import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { format, parseISO } from 'date-fns'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { PageLayout } from '../layouts'
import { PageHeader } from '../components'
import * as styles from '../styles/pages/articleTemplate'
import * as base from '../styles/base'
import variables from '../styles/variables'
import config from '../config'

const propTypes = {
  data: PropTypes.shape({
    frontmatter: PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string,
      introduction: PropTypes.string,
      date: PropTypes.string
    })
  }).isRequired
}

export default function ArticleTemplate({ data }) {
  const { frontmatter, body } = data.mdx
  const navbarProps = {
    background: variables.colors.silverTree.dark,
    textColor: 'rgba(255, 255, 255, 0.85)',
  }

  const entryDate = format(parseISO(frontmatter.date), 'MMMM d, yyyy')

  const aitoAppHostName = config.AITO_APP_HOST_NAME

  const firstChild = (
    <PageHeader
      background={variables.colors.silverTree.dark}
      header={frontmatter.title}
      info="Documentation"
    />
  )

  return (
    <PageLayout
      firstChild={firstChild}
      navbarProps={navbarProps}
      description={frontmatter.metaDescription}
      sharingTitle={`Aito.ai - ${frontmatter.title}`}
    >
      <Helmet>
        <title>{`Aito.ai - ${frontmatter.title}`}</title>
      </Helmet>
      <styles.InfoCircle>
        <styles.BookOpenIcon />

        <styles.Date>{entryDate}</styles.Date>
      </styles.InfoCircle>

      <styles.Container>
        <base.MdContainer>
          <MDXRenderer hostName={aitoAppHostName}>
            {body}
          </MDXRenderer>
          <styles.StyledLink to="/docs">
            <styles.BackArrow />
            Back to developer docs
          </styles.StyledLink>
        </base.MdContainer>
      </styles.Container>
    </PageLayout>
  )
}

ArticleTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query ArticleByPath($id: String!) {
    mdx( id: { eq: $id } ) {
      id
      body
      frontmatter {
        path
        title
        date
        metaDescription
      }
    }
  }
`
