import styled from 'styled-components'
import { Link } from 'gatsby'
import { ArrowLeft } from '@styled-icons/feather'
import { BookOpen } from '@styled-icons/fa-solid'
import variables from '../variables'

export const Container = styled.div`
  width: 100%;
  padding: 80px 0;
`

export const InfoCircle = styled.div`
  background: white;
  border-radius: 50%;
  height: 110px;
  width: 110px;
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Date = styled.p`
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 5px;
  margin: 0;
  font-size: 0.8rem;
  color: ${variables.colors.silverTree.dark};
`

export const StyledLink = styled(Link)`
  margin-top: 80px;
  font-size: 1rem;
  display: flex;
  align-items: center;
`
export const BackArrow = styled(ArrowLeft)`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`

export const BookOpenIcon = styled(BookOpen)`
  width: 50px;
  height: 50px;
  color: ${variables.colors.silverTree.dark};
`
